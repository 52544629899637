import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './App.css';
import logo from './logo.png';

function SplashPage() {
  useEffect(() => {
    // Function to check for a specific cookie
    const checkCookie = (cookieName) => {
        const cookies = document.cookie.split('; ');
        return cookies.some(cookie => cookie.startsWith(`${cookieName}=`));
    };

    // Check for the cookie
    if (checkCookie('your_cookie_name')) {
        console.log('Cookie exists!');
        // Handle the case where the cookie exists
    } else {
        console.log('Cookie does not exist.');
        // Handle the case where the cookie does not exist
    }
  }, []);
  return (
    <div className="SplashPage">
      <header className="App-header">
        <div className="header-buttons">
          <Link to="/register">
            <button className="register">Register</button>
          </Link>
        </div>
        <img src={logo} alt="Flock Logo" style={{ width: '10%', height: 'auto', marginBottom: '10px' }} />
        <h3>Flock API</h3>
        <h1>Coordinate Analysis API</h1>
        <p>
          Our AI API provides marketing analysis tailored to your business given a set of coordinates and a description of your product or service.
        </p>
        <h2>Benefits</h2>
        <ul>
          <li>Analyze spatial relationships between multiple points on a map.</li>
          <li>Determine intersections and effective areas of multiple locations.</li>
          <li>Gain insights based on custom instructions provided with the coordinates.</li>
          <li>Use cases include rideshare optimization, event impact analysis, and more.</li>
          <li>Make data-driven decisions based on real-time location analysis.</li>
        </ul>
      </header>
    </div>
  );
}

export default SplashPage;